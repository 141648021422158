.accordion-filter {
  border: 0;
  border-radius: 0;
  .accordion-item {
    border: 0;
    border-radius: 6px;
  }
  .accordion-button {
    border-radius: 6px;
    font-size: 1.1rem;
    &:not(.collapsed) {
      background: #fff;
      border-radius: 5px 5px 0 0;
      box-shadow: none;
    }
    &:focus {
      box-shadow: none;
    }
    &::after {
      width: 15px;
      height: 15px;
      background-size: 15px;
    }
    .count {
      color: #3ec60e;
    }
  }
  .accordion-body {
    padding-top: 4px;
  }
}
