#productPairing {
  margin: 4rem auto 0 0;
  background: #f4f4f4;
  padding: 4rem 0;
  .alternative-title {
    margin-bottom: 3rem;
  }

  .product-card {
    padding: 1.5rem;
    margin: 0.25rem;
    &:after {
      content: "";
      background: url("../../Assets/Images/Svgs/plus.svg") center center
        no-repeat;
      background-size: contain;
      height: 26px;
      width: 26px;
      top: 50%;
      position: absolute;
      transform: translateY(-50%);
      right: -40px;
    }
  }

  @media (max-width: 767px) {
    .product-card {
      padding: 1.25rem;
    }
    padding: 2rem 0 2.5rem;
    margin: 1rem 0 0 0;
    .alternative-title {
      margin-bottom: 1rem;
    }
    h2 {
      text-align: left;
      font-size: 18px;
    }
  }
}
