.bug-report-form {
    font-family: 'Overpass';
    color: #4e4e4e;
    min-height: 400px;
    form {
        display: flex;
        flex-direction: column;
         label {
            margin: 1rem 0;
         }
         input , textarea {
            border: 1px solid #ddd;
            border-radius: 4px;
            padding: 10px;
         }
         textarea{
            margin-bottom: 1rem;
         }
         button {
            background: #2C7765;
            border-radius: 5px;
            width: 50%;
            margin: 2rem auto 1rem auto;
            color: #fff;
            text-transform: uppercase;
            border: 0;
            padding: 1rem 0;
         }
    }

    .form-submitted {
      margin: 2rem 0;
      color: #54AA95;
      font-size: 14px;
      line-height: 150%;
    }

    @media(max-width:767px) {
       form {
         button {
            line-height: 1.45;
            padding: 0.75rem 0 0.5rem 0;
            font-weight: 700;
            letter-spacing: 0.4px;
            max-width: 145px;
         }
       }
    }
}