#greenscore {
  .common-padding {
    padding: 4rem 0;
  }
  .banner {
    background-color: #fff8e7;
  }
  .data-sources {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 20px;
    margin: 3rem 0;
  }
  .scores {
    margin-bottom: 0;
    li {
      display: flex;
      align-items: center;
      margin: 14px 0;
      &:first-child {
        margin-bottom: 0;
      }
      img {
        margin-right: 15px;
      }
      p {
        margin: 0;
        font-weight: 600;
        min-width: 80px;
        text-align: left;
      }
      span {
        min-width: 200px;
        text-align: left;
      }
    }
    &.right {
      li {
        justify-content: flex-end;
        img {
          margin-right: 0px;
        }
        p,
        span {
          text-align: right;
        }
      }
    }
  }

  .gs-content {
    line-height: 1.35;
  }

  .rating-section {
    background: #fbfbfb;
    overflow: hidden;
    img {
      width: 75%;
      object-fit: cover;
      margin: 2rem auto;
      display: block;
    }
    @media (max-width: 767px) {
      img {
        width: 100%;
        transform: scale(1.06);
      }
    }
  }

  #scoresSection {
    & > div:not(:last-child) {
      border-bottom: 1px solid #eaeaea;
    }
  }

  @media (max-width: 991px) {
    .data-sources {
      grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
    }
  }

  @media (max-width: 576px) {
    .common-padding {
      padding: 2rem 1rem;
    }
    .banner {
      padding: 4rem 1rem 0 1rem;
    }
    .scores {
      li {
        justify-content: flex-start !important;
        align-items: flex-start !important;
        img {
          width: 16px;
          height: 16px;
          margin-top: 2px;
          margin-right: 15px !important;
        }
        span {
          text-align: left !important;
          margin-left: 8px;
        }
        p {
          text-align: left !important;
        }
      }
    }
    .data-sources {
      grid-template-columns: repeat(auto-fit, minmax(175px, 1fr));
    }
  }
}
