.ant-spin {
  span,
  .ant-spin-text,
  .ant-spin-dot-item {
    color: #2c7765;
  }
  .ant-spin-dot-item {
    background-color: #2c7765;
  }
}

.error-fallback {
  min-height: calc(100vh - 150px - 2rem);
}

.error-fallback > div {
  padding: 2rem;
  margin-top: 4rem;
  background-color: #ffd4da;
  border-radius: 0.25rem;
}

.error-fallback h2 {
  font-size: 1.75rem;
  font-weight: bold;
  margin-bottom: 1rem;
}

.error-fallback p {
  margin-bottom: 1rem;
}

.error-fallback pre {
  white-space: pre-wrap;
  font-size: 1rem;
  margin-bottom: 1rem;
}

.error-fallback button {
  background-color: #e34d4d;
  color: white;
  border: none;
  border-radius: 0.25rem;
  padding: 0.5rem 1rem;
  cursor: pointer;
  font-size: 1rem;
}

.error-fallback button:hover {
  background-color: #c82333;
}
