.not-found {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  font-family: Overpass, sans-serif;

  .not-found-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    padding: 2rem;
    background-color: #fff;
    border-radius: 10px;
    box-shadow: 0 0 5px 2px #2c776631;

    .not-found-heading {
      font-size: 5rem;
      font-weight: bold;
      margin: 0;
    }

    .not-found-text {
      font-size: 1.5rem;
      margin: 0;
      margin-top: 1rem;
    }

    .not-found-link {
      display: inline-block;
      font-size: 1.2rem;
      line-height: 1.5;
      margin: 2rem 1rem;
      padding: 0.8rem 1.5rem;
      color: #fff;
      background-color: #2c7766eb;
      border-radius: 50px;
      text-decoration: none;
      transition: background-color 0.2s ease-in-out;

      &:hover {
        background-color: #2c7766;
      }
    }
  }

  @media (max-width: 767px) {
    .not-found-container {
      box-shadow: none;
      .not-found-heading {
        font-size: 38px;
      }
      .not-found-text {
        font-size: 18px;
        margin-bottom: 1.5rem;
      }
      & > div {
        flex-direction: column;

        a {
          font-size: 1rem !important;
          margin: 0.4rem auto !important;
          min-width: 210px;
        }
      }
    }
  }
}
