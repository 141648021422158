#terms-and-conditions {
    .terms-wrap {
        padding: 3rem 0;
    }
    ol {
        li {
            margin: 2.5rem 0;
             &::marker {
                font-size: 22px;
                font-weight: 600;
             }
             h2 {
                font-weight: 600;
                margin-bottom: 1rem;
             }
        }
    }

    @media(max-width:767px) {
        .terms-wrap {
                padding: 3rem 0 1.5rem 0;
        }
        ol {
            li{
                &::marker {
                    font-size: 17px;
                }
            }
        }
    }
}