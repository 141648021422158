.preference-setup-wrapper {
  background: #f6c06d;
  padding: 25px;
}

.text-heading {
  font-size: 22px;
  font-weight: 700;
  line-height: 30px;
}

.text-paragraph {
  color: #2c7765;
  text-align: center;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 24px;
}

.pref-set-up-btn {
  border-radius: 5px;
  background: #e8733e;
  color: #fff;
  text-align: center;
  font-size: 16px;
  font-weight: 700;
  line-height: 28px;
  padding: 12px 16px;
  border: 0;
  max-width: 80%;
  &:active,
  &:focus,
  &:hover {
    color: #fff;
  }
}
