.pdp-info-tabs {
  .ant-tabs-nav-list {
    flex: 1 1;
    border-bottom: 1px solid #ddd;
    padding: 0 5px;

    .ant-tabs-tab {
      flex: 1;
      padding: 10px;
      justify-content: center;
      margin: 0 10px;
      & > div {
        color: #929292;
        font-family: Overpass;
        font-size: 21px;
        font-weight: 400;
        line-height: 28px;
      }
    }
    .ant-tabs-tab-active {
      & > div {
        color: #2c7765 !important;
        font-weight: 700;
      }
    }
    .ant-tabs-ink-bar {
      background: #2c7765;
    }
  }

  @media(min-width:767px) {
     margin-top: 2rem;
     padding: 0 1.25rem;
  }
}
