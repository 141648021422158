.filter-wrap {
  background-color: #f7f5f8;
  min-height: 100vh;
  .heading {
    font-size: 18px;
    color: #4e4e4e;
    margin: 0;
  }

  .filter-btns {
    .apply-btn {
      background: #2c7765;
      color: #fff;
      border-color: #2c7765;
      font-size: 16px;
    }
  }

  .btn-reset {
    border: 1px solid #505050;
    border-radius: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 12px 20px 10px;
    line-height: 1;
    color: #4e4e4e;
    font-size: 16px;
    transition: 200ms all ease-in-out;
    &:hover,
    &:focus {
      border: 1px solid #505050;
    }
    &:active {
      transform: scale(0.95);
    }
  }
}
