.footer {
  background: #2c7765;
  padding: 1rem 0;
  z-index: 999;
  position: relative;
  overflow: hidden;
  a {
    color: #fff;
    text-decoration: none;
    &:first-child {
      padding-right: 4rem;
    }
    &:hover {
      color: #fff;
    }
  }

  @media (max-width: 767px) {
    padding: 2rem 0 1.5rem 0;
    .footer-content {
        flex-direction: column-reverse;
        justify-content: center !important;
        text-align: center !important;

         img {
            margin-top: 0.5rem;
         }
    }
    .footer-links {
      display: flex;
      flex-direction: column;
      a {
        font-size: 14px;
        padding-right: 0;
        padding-bottom: 12px;
      }
    }
  }
}
