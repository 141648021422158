.swiper {
    overflow: hidden;
    .swiper-slide:last-child {
        .product-card {
            &:after {
                display: none;
            }
        }
    }
}

#alternatives .swiper {
    .swiper-wrapper {}

    position: relative;

    &:hover {

        .swiper-button-next,
        .swiper-button-prev {
            opacity: 1;
            visibility: visible;
        }
    }

    @media(max-width:767px) {
        padding: 0 !important;
    }
}

#alternatives .swiper-button-next,
#alternatives .swiper-button-prev {
    position: absolute;
    opacity: 0;
    visibility: hidden;
    background: #a4c3b2 !important;
    border-radius: 50%;
    width: 48px !important;
    height: 48px !important;
    right: 0 !important;
    transition: all .2s ease-in-out;
    top: 40%;
    z-index: 999;
    cursor: pointer;

    @media (max-width:767px) {
        display: none;
    }
}

.swiper-button-prev {
    left: 0 !important;
}

.swiper-button-next:after,
.swiper-button-prev:after {
    background: url("../../Assets/Images/Svgs/arrow.svg") no-repeat !important;
    content: "" !important;
    height: 100%;
    width: 100%;
    display: block;
    -webkit-filter: brightness(0) invert(1);
    filter: brightness(0) invert(1);
    background-position: 50% !important;
    background-repeat: no-repeat !important;
}

@media(max-width:767px) {
    .swiper-button-next:after,
            .swiper-button-prev:after {
                display: none;
            }
}

.swiper-button-prev:after {
    transform: rotate(180deg);
}

.swiper-button-next.swiper-button-disabled,
.swiper-button-prev.swiper-button-disabled {
    display: none !important;
}