.toggle-filter-wrap {
  .form-switch {
    .form-check-label {
      color: #4e4e4e;
      margin: 8px 0px 0px 8px;
      line-height: 1;
    }
    .form-check-input {
      background-color: #d3d3d3;
      background-image: url("../../Assets/Images/filter-toggle.svg");
      width: 36px;
      height: 22px;
      &:checked {
        background-color: #3ec60e;
        border-color: #3ec60e;
      }
      &:focus {
        box-shadow: none;
        border: none;
      }
    }
  }
}
