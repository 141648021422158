.pdp-filter-list {
  width: 85%;
  margin: 0 auto 20px auto;

  .filter-list {
    display: flex;
    gap: 0.65rem;
    padding: 0.65rem 1rem;
    align-items: center;
    p {
      padding-top: 3px;
      font-size: 14px;
      font-weight: 500;
    }
    &.rounded-top-only {
      border-top-left-radius: 0.75rem;
      border-top-right-radius: 0.75rem;
    }
    &.rounded-bottom-only {
      border-bottom-left-radius: 0.75rem;
      border-bottom-right-radius: 0.75rem;
    }
  }

  .more-less-show-btn {
    background-color: #fff;
    outline: none;
    border: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    color: #4e4e4e;
    width: 100%;
    margin: 0.4rem auto 0 auto;
    font-size: 14px;
  }

  @media (max-width: 767px) {
    margin: 0 auto 8px auto;
  }
}
