@import "../../Styles/variables";

.tabHeading {
    font-family: $font-secondary;
    font-weight: 900;
    font-size: 40px;
    line-height: 56px;
    ;
    letter-spacing: 0.01em;
    color: $primary;

    @media(max-width:767px) {
        font-size: 28px;
        line-height: 39px;
        margin-bottom: 0;
    }
}

.pdpTabs {
    @media(max-width:767px) {
        margin-bottom: 2rem;
    }

    .factsTab {
        margin-top: 10px;

        h3 {
            @extend .tabHeading;
        }

                   .svg-wrapper {
                    display: inline-block;
                    margin-right: 12px;
                   }

        &>div {
            display: grid;
            grid-template-columns: repeat(2, 1fr);
            align-items: flex-start;
            margin-top: 20px;

            h4 {
                font-family: $font-primary;
                font-weight: 600;
                font-size: 24px;
                line-height: 34px;
                color: $primary;
                margin-bottom: 12px;

                img {
                    margin-right: 8px;
                }
            }

            ul {
                display: flex;
                align-items: center;
                flex-wrap: wrap;
                margin-left: 32px;

                li {
                    font-family: $font-primary;
                    font-style: italic;
                    font-weight: 500;
                    font-size: 20px;
                    line-height: 24px;
                    color: $secondary;
                    margin: 0 10px 8px 0;

                    a {
                        margin-top: 16px;
                        color: $primary;
                        background: #C7E1DA;
                        font-size: 16px;
                        line-height: 1.1;
                        padding: 10px 16px;
                        font-style: normal;
                        border-radius: 8px;
                        margin: 4px 0 0 0;
                        display: block;
                        transition: all 200ms ease-in-out;

                        &:hover {
                            background: $primary;
                            color: #fff;
                        }
                    }

                }
            }

            &>div:first-child,
            &>div:last-child {
                ul {
                    li {
                        margin: 0 8px 8px 0;

                        &:after {
                            content: ',';
                        }

                        &:last-child {
                            &:after {
                                content: '';
                            }
                        }
                    }
                }
            }
        }

        @media(max-width:767px) {
            margin-top: 5px;

            .svg-wrapper {
                margin-right: 0;
            }

            &>div {
                grid-template-columns: 100%;
                margin-top: 15px;

                h4 {
                    font-size: 18px;
                    line-height: 25px;
                    margin-bottom: 12px;
                }

                &>div {
                    margin-bottom: 16px;

                    &:nth-child(2) {
                        margin-bottom: 0;
                        order: 3;
                        overflow: hidden;
                        ul {
                            justify-content: flex-start;
                            li {
                                flex: 0 0 auto;
                                 padding: 10px 12px;
                                a {
                                    margin-top: 2px;
                                }
                            }
                        }
                    }
                }

                svg {
                    width: 25px;
                    height: 25px;
                    margin-right: 5px !important;
                }

                ul {
                    margin-left: 24px;
                }

                li {
                    font-size: 16px !important;
                    line-height: 19px !important;
                }
            }
        }
    }

    .ingredientsTab {
        margin-top: 10px;

        h2 {
            @extend .tabHeading;

            span {
                font-size: 20px;
                font-family: $font-secondary;
                font-weight: 400;
                color: #929292;
                line-height: 24px;
                margin-top: 8px;
                text-transform: capitalize;
            }
        }

        div {
            background: #fff;
            margin-top: 24px;
            padding: 16px 20px;
            border-radius: 8px;

            & p {
                font-family: $font-primary;
                font-weight: 300;
                font-size: 18px;
                line-height: 36px;
                color: $secondary;
            }
        }

        @media(max-width: 767px) {
            h2 {
                span {
                    font-size: 16px;
                }
            }

            div p {
                font-size: 16px;
                line-height: 32px;
            }
        }
    }

    .nutriTab {
        margin-top: 10px;

        h3 {
            @extend .tabHeading;
        }

        &>div {
            background: #fff;

            p {
                font-size: 18px;
                color: $secondary;
            }
        }
    }
}


  .nutrition_facts_tab_chart {
    grid-template-columns: 100%;

    & > div {
      border: 0 !important;
    }

    & > div:nth-child(1) {
      padding-top: 30px !important;
      padding-left: 0px !important;
    }

    & table {
      width: 100%;

      & th {
        border-top: 1px solid #ddd;
      }
    }
  }

.nutrition_facts_tab_chart {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    border-top: 0 !important;
    border-bottom-right-radius: 10px;
    border-bottom-left-radius: 10px;
    padding: 0 30px;
    font-family: Overpass;
    overflow: hidden;
    color: #454545;
    border-radius: 8px;
    margin-top: 30px
}

.nutrition_facts_tab_chart>div:first-child {
    padding: 36px 80px 0 20px;
    border-right: 1px solid #ddd
}

.nutrition_facts_tab_chart>div:first-child>div:first-child {
    border-bottom: 1px solid #ddd
}

.nutrition_facts_tab_chart>div:first-child>div:last-child {
    margin-top: 16px
}

.nutrition_facts_tab_chart>div:first-child h3 span:first-child {
    font-family: "Overpass";
    font-style: normal;
    font-weight: 800;
    font-size: 18px;
    line-height: 25px;
    color: #454545
}

.nutrition_facts_tab_chart>div:first-child h3 span:last-child {
    font-family: "Overpass";
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 25px;
    color: #454545
}

.nutrition_facts_tab_chart>div:nth-child(2) {
    border-right: 1px solid #ddd
}

.nutrition_facts_tab_chart table {
    margin: 20px auto;
    width: 75%
}

.nutrition_facts_tab_chart table tr td:first-child,
.nutrition_facts_tab_chart table tr td:nth-child(2) {
    width: 110px
}

.nutrition_facts_tab_chart table tr.main-tr td {
    padding-bottom: 5px
}

.nutrition_facts_tab_chart table tr.sub-tr>td:first-child {
    font-size: 12px;
    font-weight: 400
}

.nutrition_facts_tab_chart table tr.sub-tr td {
    border: 0;
    padding: 5px 0
}

.nutrition_facts_tab_chart table th {
    color: #8e8e8e;
    border-top: 0;
    border-bottom: 0
}

.nutrition_facts_tab_chart table td,
.nutrition_facts_tab_chart table th {
    font-weight: 700;
    font-size: 14px;
    line-height: 20px;
    padding: 14px 0
}

@media (max-width: 767px) {
    .nutrition_facts_tab_chart {
        grid-template-columns: 100%
    }

    .nutrition_facts_tab_chart>div {
        border: 0 !important
    }

    .nutrition_facts_tab_chart>div:first-child {
        padding: 30px 0 0 !important
    }

    .nutrition_facts_tab_chart>div:last-child table {
        margin: 0
    }

    .nutrition_facts_tab_chart>div:last-child table thead {
        display: none
    }

    .nutrition_facts_tab_chart table {
        width: 100%
    }

    .nutrition_facts_tab_chart table th {
        border-top: 1px solid #ddd
    }
}


.pdpTabs .ant-tabs-nav-wrap .ant-tabs-nav-list .ant-tabs-tab.ant-tabs-tab-active>div {
    color: #2c7765;
    font-weight: 700;
}

.pdpTabs .ant-tabs-nav-wrap .ant-tabs-nav-list {
    flex: 1 1;
    border-bottom: 3px solid #ddd;
    @media(max-width:767px) {
            .ant-tabs-tab {
                padding: 5px 0 !important;
    
                &>div {
                    font-size: 16px !important;
                    line-height: 22px !important;
                }
            }
        }
}

.pdpTabs .ant-tabs>.ant-tabs-nav .ant-tabs-nav-list,
.pdpTabs .ant-tabs>div>.ant-tabs-nav .ant-tabs-nav-list {
    position: relative;
    display: flex;
    transition: transform .3s;
}

.pdpTabs .ant-tabs-large>.ant-tabs-nav .ant-tabs-tab {
    padding: 16px 0;
    font-size: 16px;
}

.pdpTabs .ant-tabs-nav-wrap .ant-tabs-nav-list .ant-tabs-tab {
    flex: 1 1;
    text-align: center;
    justify-content: center;
}

.pdpTabs .ant-tabs-nav-wrap .ant-tabs-nav-list .ant-tabs-tab>div {
    font-family: "Overpass", sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 24px;
    line-height: 34px;
    text-align: center;
    color: #929292;
}

.pdpTabs .ant-tabs-nav-wrap .ant-tabs-nav-list .ant-tabs-ink-bar {
    top: 100%;
    background: #2c7765;
}

.ant-tabs-bottom>.ant-tabs-nav .ant-tabs-ink-bar,
.ant-tabs-bottom>div>.ant-tabs-nav .ant-tabs-ink-bar,
.ant-tabs-top>.ant-tabs-nav .ant-tabs-ink-bar,
.ant-tabs-top>div>.ant-tabs-nav .ant-tabs-ink-bar {
    height: 2px;
}

.disclaimer {
    text-align: center;
    padding-top: 1rem;
    svg {
        margin-right: 4px;
    }
    @media(max-width:767px) {
        padding-top: 2rem;
        p {
            display: flex;
            padding: 0 10px;
            line-height: 1.45;
        }
        svg {
            width: 16px;
            height: 16px;
        }
        font-size: 14px;
        text-align: left;
    }
}