$orange : #D78150;
$cartButton : #E59861;
$border : #ddd;
$primary : #2C7765;
$secondary : #4e4e4e;
$font-primary: 'Overpass', sans-serif;
$font-secondary: 'Chivo', sans-serif;
$gray : #929292;
$light-gray: #8b8b8b;
$light-green: #c7e1da;
$bg-primary : #FDF4E0;
$subTextColor: #a1a1a1;
$darktext: #494949;