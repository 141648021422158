#productDetailPage {
    overflow-x: hidden;
    a {
        text-decoration: none;
    }
}

.conflictWarning {
    display: none;
}

.conflictWarningWrapper {
    position: absolute;
    width: 100%;
    left: 33%;
    top: 28%;
    transform: translate(-50%, -20%);
    z-index: 999;
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
    max-width: 515px;
    width: 100%;
    margin: 5px auto;

    @media(max-width: 767px) {
        left: 50%;
        top: 30%;
        max-width: 350px;
        margin: 5px auto;
    }
}

.conflictWarning.show {
    display: block;
    padding: 7px 15px 5px 15px;
    width: 100%;
    margin: 5px auto;
    background: #FFD4DA;
    border-radius: 8px;
    text-align: center;
    position: relative;

    h4,
    li {
        font-family: 'Overpass';
        font-weight: 400;
        font-size: 16px;
        line-height: 21px;
        color: #E34D4D;
    }

    h4 {
        font-weight: 700;
        margin: 2px;
        font-size: 15px;
    }

    button {
        font-family: 'Chivo';
        position: absolute;
        right: 20px;
        top: 8px;
        font-weight: 500;
        font-size: 20px;
        line-height: 21px;
        background: transparent;
        border: 0;
        color: #E34D4D;
    }

    & ul {
        display: flex;
        align-items: center;
        justify-content: center;

        & li {
            text-transform: capitalize;
            margin: 0 2px;
        }

        &.allergyConflictList {
            li {
                &:not(:last-child)::after {
                    content: ',';
                    position: relative;
                    left: -2px;
                }

                &:first-child::after {
                    display: none
                }
            }
        }

        &.dietConflictList {
            li {
                &:not(:last-child)::after {
                    content: ',';
                    position: relative;
                    left: 1px;
                }
            }
        }
    }

    @media(max-width:767px) {
        padding: 8px 15px 6px 15px;
        margin: 2px auto;

        ul {
          margin: 2px;
        }

        h4,
        li {
            font-size: 12px;
            line-height: 18px;
        }

        h4 {
            margin-bottom: 0px;
        }

        button {
            top: 3px;
            right: 5px;
            font-size: 16px;
        }
    }
}