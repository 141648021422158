.more-options-wrap {
  background-color: #f7f5f8;
  padding: 2rem 0;
  ul {
    padding: 0.25rem 0;
    display: flex;
    flex-direction: column;
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 20px;
    li {
      background: #fff;
      border-radius: 10px;
      a {
        color: #4e4e4e;
        font-size: 14px;
        padding: 0.75rem 0.8rem;
        display: inline-block;
        width: 100%;
      }
    }
  }

  @media (max-width: 767px) {
    ul {
      display: flex;
      gap: 11px;
    }
  }
}
