.product-card.card {
  border: 0;
  position: relative;
  .ant-progress-circle {
    text-align: right;
    .ant-progress-inner {
      position: absolute;
      right: 10px;
      top: 12px;
      z-index: 9;
    }
    .ant-progress-text {
      font-size: 14px;
      color: #4e4e4e;
      padding-top: 1px;
      @media (max-width: 767px) {
        font-size: 14px;
        padding-top: 2.5px;
        line-height: 1.25;
      }
    }
  }

  .product-img {
    width: 175px;
    height: 175px;
    object-fit: contain;
    margin: 0 auto 1.75rem auto;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: transparent;
  }

  .product-title {
    text-transform: capitalize;
    line-height: 24px;
    margin-bottom: 0;
    min-height: 50px;
    max-height: 50px;
    color: #4e4e4e;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  @media (max-width: 767px) {
    .product-img {
      width: 140px;
      height: 140px;
      margin-top: 1rem;
    }
  }
}
