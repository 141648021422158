#alternatives {
  margin: 4rem auto;

  .alternative-title {
    margin-bottom: 3rem;
  }

  .product-card {
    padding: 0.75rem;
  }

  @media (max-width: 767px) {
    margin: 0.75rem auto;
    .alternative-title {
      h2 {
        padding: 0 2rem;
        line-height: 26px;
        font-size: 18px;
      }
      margin-bottom: 1rem;
    }
  }
}
