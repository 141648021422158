.filter-selector-wrap {
  ul {
    display: flex;
    flex-wrap: nowrap;
    justify-content: flex-start;
    align-items: center;
    overflow-x: auto;
    overflow-y: hidden;
    -ms-overflow-style: none;
    scroll-behavior: smooth;
    gap: 0.35rem;
    max-width: 95vw;
    scrollbar-width: none;

    &::-webkit-scrollbar {
      display: none;
    }
    li {
      flex: 0 0 auto;
      color: #4e4e4e;
      border: 1px solid #999;
      padding: 0.4rem 0.5rem 0.3rem 1rem;
      font-size: 14px;
      border-radius: 1.5rem;
    }
  }
}
