.skeleton-product-banner {
  margin: 3rem 0;
  display: grid;
  gap: 1rem;
}

.skeleton-row {
  display: grid;
  grid-template-columns: 7% 38% 52%;
  gap: 3%;
}

.skeleton-image-section {
  display: flex;
  gap: 1rem;
  flex-direction: column;
}

.skeleton-thumbnail {
  width: 60px;
  height: 60px;
  background: #e0e0e0;
  border-radius: 5px;
}

.skeleton-main-image {
  width: 100%;
  height: 400px;
  background: #e0e0e0;
  border-radius: 5px;
}

.skeleton-details-section {
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  gap: 1rem;
  margin-left: 3rem;
}

.skeleton-title-wrap {
  display: flex;
  align-items: center;
  padding-bottom: 3rem;
  border-bottom: 1px solid #e0e0e0;
}

.skeleton-title {
  width: 80%;
  p {
    width: 90%;
    height: 18px;
    background: #e0e0e0;
    border-radius: 5px;
  }
}

.skeleton-progress {
  width: 90px;
  height: 90px;
  background: #e0e0e0;
  border-radius: 50%;
}

.skeleton-filter-list {
  margin-top: 5rem;
  width: 50%;
  height: 20px;
  background: #e0e0e0;
  border-radius: 5px;
}

.skeleton-values {
  display: flex;
  gap: 0.5rem;
}

.skeleton-value {
  width: 80px;
  height: 32px;
  background: #e0e0e0;
  border-radius: 5px;
}

/* Responsive */
@media (max-width: 767px) {
  .skeleton-row {
    display: flex;
  }
  .skeleton-product-banner {
    display: flex;
    margin: 2rem 0 0 0;
  }
  .skeleton-thumbnail {
    display: none;
  }
  .skeleton-filter-list {
    margin-top: 1rem;
  }
  .skeleton-main-image {
    width: 100px;
    height: 125px;
  }
  .skeleton-progress {
    width: 60px;
    height: 60px;
  }
  .skeleton-value {
    width: 110px;
  }
  .skeleton-details-section {
    margin-left: 1.25rem;
    width: 100%;
  }
  .skeleton-title-wrap {
    width: 100%;
    padding-bottom: 1rem;
    flex-direction: column;
    height: auto;
    justify-content: flex-start;
    align-items: flex-start;
  }
  .skeleton-title p {
    width: 125px;
  }
}
