.disclaimer-modal {
    font-family: 'Overpass';
    h4 {
        color: #4e4e4e;
        margin-bottom: 16px;
    }
    a, p {
        color: #5C5C5C;
    }
    a:hover {
        color: #4e4e4e;
    }
}