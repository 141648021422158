@import "../../Styles/_variables.scss";
#glossary {
  .banner {
    padding: 4rem 0;
    background: #f0f7f5;
    h1 {
      margin-bottom: 2rem;
    }
    @media (max-width: 767px) {
      padding: 2.5rem 0;
      h1 {
        margin-bottom: 1.5rem;
      }
    }
  }
  .table-of-contents {
    margin-top: 4rem;
    h2 {
      margin-bottom: 1.5rem;
    }
    ul {
      margin: 0 1.5rem;
      li {
        padding: 0.5rem 0.25rem;
        list-style: decimal;
        a {
          text-decoration: none;
          color: $secondary;
          &:hover {
            color: $orange;
          }
        }
      }
    }
  }
  .filter-wraps {
    padding: 4rem 0;
    scroll-padding-top: 6rem;
    h3 {
      margin-bottom: 2rem;
    }
    * {
      font-family: Overpass, sans-serif;
    }
    @media (max-width: 767px) {
      padding: 2.5rem 0;
      h3 {
        font-size: 21px;
      }
    }
    .ant-collapse {
      border: 0;
      background: #fff;
      .ant-collapse-item {
        margin: 1rem 0;
        background: #fff;
      }

      .ant-collapse-item-active {
        .ant-collapse-header {
          padding-bottom: 0;
        }
        .ant-collapse-header-text {
          color: $primary;
        }
      }

      .ant-collapse-header {
        background: #fff;
        font-size: 18px;
        font-weight: 600;
        border-bottom: 0;
        .ant-collapse-expand-icon {
          order: 2;
        }
      }

      .ant-collapse-content {
        border-top: 0;
        font-size: 16px;
      }
    }
  }
}
