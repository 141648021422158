@import url('https://fonts.googleapis.com/css2?family=Chivo:wght@300;400;700;900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Overpass:wght@100;200;300;400;600;700;800;900&display=swap');

@import "variables";

* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    touch-action: manipulation;
     
}

#root {
    @media(max-width:767px) {
            width: 100%;
        }
}

body {
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    height: 100%;
    overflow-x: hidden;
    font-family: $font-primary !important;
    line-height: 1.35;
    color: $secondary !important;
    font-size: 16px;
    scroll-behavior: smooth;
    transition: all 200ms linear;
}

.cursor-pointer {
    cursor: pointer;
}

ul {
    padding: 0;
     li {
        list-style: none;
     }
}

.text-primary {
    font-family: $font-primary;
}

.text-dark {
    color: $secondary;
}

.primary-color {
    color: $primary;
    
}

.btn-green {
    padding: 0.5rem 1rem;
    background: $light-green;
    color: $primary !important;
    font-size: 16px !important;
    font-style: normal !important;
    border-radius: 5px;
    margin: 0 10px 10px 0 !important;
}

.w-90 {
    width: 90%;
}

.loader {
    position: fixed;
    width: 100vw;
    height: 100vh;
    left: 0;
    top: 0;
    z-index: 99999;
    color: $primary;
    background: $bg-primary;
    .ant-spin {
       position: absolute;
       top: 50%;
       left: 50%;
       transform: translate(-50%, -50%);
       font-family: $font-secondary;
    }
    span, .ant-spin-text, .ant-spin-dot-item  {
        color: $primary;
        font-family: $font-secondary;
    }
     .ant-spin-dot-item {
        background-color: $primary;
     }
}

._hj-YR-2H__Feedback__container {
    z-index: 99 !important;
}

.Toastify .Toastify__toast-body{
    font-family: Overpass;
}