.header {
    border-bottom: 1px solid #ddd;
    padding: 10px 0;
    position: sticky;
    top: 0;
    width: 100%;
    z-index: 99999;
    background: #fff;
    min-height: 50px;
    .powered-by-gc {
      // position: absolute;
      // right: 0;
      // top: 50%;
      // transform: translateY(-50%);
    }
    .store-logo {
       width: 100px;
       height: 60px;
       object-fit: contain;
    }
    .navbar-toggler {
      &:focus {
         outline: none;
         box-shadow: none !important;
      }
    }
   .navbar-collapse {
      width: 20%;
      position: absolute;
      left: 0;
      right: 0;
      top: 0;
      bottom: 0;
      padding: 15px;
      background: #fff;
      height: 100vh;
      transform: translateX(-100%);
      transition: all 150ms ease-in-out;
      font-family: Overpass;
      box-shadow: 1px 1px 17px 0px rgba(0, 0, 0, 0.2);
      &.collapse{
         &.show{
            transform: translateX(0%); 
         }
      }
      .close-btn {
         background: transparent;
         border: none;
      }
      .nav-link {
         color: #4e4e4e;
           &:hover {
             color: #2C7765;
           }
      }
   }
     @media (max-width: 767px) {
        padding: 3.5px 0;
        min-height: auto;
        height: 65px;
        .store-logo {
           width: 75px;
           height: 50px;
           object-fit: contain;
        }

        .navbar-collapse {
          width: 100%;
        }
     }

}